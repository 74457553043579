// 质检记录
<template>

  <div>
    <a-collapse v-model:activeKey="typeName" accordion @change="aFold">
      <a-collapse-panel  header="筛选框" key="1">
        <el-form ref="searchForm" :inline="true" size="mini" :model="searchForm" label-position="center"
          label-width="100px">
          <div class="flex-nowrap-space-between">
            <div class="div-width-radio-group">
              <div>
                <el-form-item label="质检状态">
                  <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">
                    全部
                  </el-checkbox>
                  <el-checkbox-group v-model="searchForm.translatedResultList" @change="handleCheckedCitiesChange">

                    <el-checkbox v-for="(item,i) in cities" :key="i" :label="item.label">{{
                                            item.name
                                          }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="是否命中">
                  <el-radio-group v-model="searchForm.hitTypeStatus" class="ml-4">
                    <el-radio label="" size="large">全部</el-radio>
                    <el-radio label="1" size="large">命中</el-radio>
                    <el-radio label="0" size="large">未命中</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="质检状态">
                  <el-checkbox v-model="checkAll2" :indeterminate="isIndeterminate2" @change="handleCheckAllChange2">
                    全部
                  </el-checkbox>
                  <el-checkbox-group v-model="searchForm.auditStatusList" @change="handleCheckedCitiesChange2">

                    <el-checkbox v-for="(item2,s) in cities2" :key="s" :label="item2.label">{{
                                            item2.name
                                          }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>

              <div>
                <el-form-item label="审核状态">
                  <el-radio-group v-model="searchForm.read" class="ml-4">
                    <el-radio label="" size="large">全部</el-radio>
                    <el-radio label="0" size="large">未读</el-radio>
                    <el-radio label="1" size="large">已读</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>

            </div>
            <div>
              <div>
                <el-form-item label="行业类型">
                  <el-select v-model="searchForm.industry" placeholder="行业类型">
                    <el-option :label="item" :value="item" v-for="(item,i) in options2" :key="i"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="命中类型">
                  <div class="conditions-box flex-nowrap-space-between">
                    <div class="conditions-in-title">
                      条件
                    </div>
                    <div class="conditions-in-first">
                      <el-select v-model="searchForm.value" class="sel m-2" placeholder="请选择" size="mini">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </div>
                    <div class="conditions-in-first">
                      <el-select v-model="searchForm[searchForm.value]" class="sel m-2" placeholder="请选择" filterable
                        size="mini">
                        <el-option v-for="item in options3" :key="item.name" :label="item.name" :value="item.name" />
                      </el-select>
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="敏感词">
                  <el-input v-model="searchForm.translatedContent" clearable placeholder="敏感词"></el-input>
                </el-form-item>
              </div>

              <div>
                <el-form-item label="风险等级">
                  <el-select v-model="searchForm.risk" placeholder="请选择" style="" clearable size="mini">

                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

            </div>
            <div>
              <div>
                <el-form-item label="主叫前缀">
                  <el-input v-model="searchForm.telAPrefix" clearable placeholder="主叫前缀"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="主叫号码">
                  <el-input v-model="searchForm.telA" clearable placeholder="主叫号码"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="主叫敏感词">
                  <el-input v-model="searchForm.callerTranslatedContent" clearable placeholder="主叫敏感词"></el-input>
                </el-form-item>
              </div>
            </div>
            <div>
              <div>
                <el-form-item label="被叫前缀">
                  <el-input v-model="searchForm.telBPrefix" clearable placeholder="被叫前缀"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="被叫号码">
                  <el-input v-model="searchForm.telB" clearable placeholder="被叫号码"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="被叫敏感词">
                  <el-input v-model="searchForm.calleeTranslatedContent" clearable placeholder="被叫敏感词"></el-input>
                </el-form-item>
              </div>

            </div>
          </div>
          <div class="flex-nowrap-space-between">
            <el-form-item label="时间筛选">
              <el-date-picker v-model="searchForm.timeArr" type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss"
                :default-time="[new Date(2000, 1, 1, 8, 0, 0),new Date(2000, 2, 1, 24, 0, 0),]" :shortcuts="shortcuts"
                range-separator="-" start-placeholder="开始" end-placeholder="结束" />
            </el-form-item>

            <div>
              <el-button type="primary" size="small" @click="inquire">查询</el-button>
              <el-button size="small" @click="blick">清空筛选条件</el-button>
            </div>
          </div>

        </el-form>
      </a-collapse-panel>
    </a-collapse>
    <div>
      <el-table class="eltable" ref="multipleTable" v-loading="loadingSecond" :data="tableData.records"
        tooltip-effect="dark" style="width: 100%" :row-style="{height:'50px'}" :cell-style="{padding:'0px'}" size="mini"
        :height="tableHeight+'vh'" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
        :row-class-name="tableRowClassName" @row-click="openDiv">
        <el-table-column prop="company" label="企业名称">
        </el-table-column>
        <el-table-column prop="industry" label="行业">
        </el-table-column>
        <el-table-column label="前缀">
          <template #default="scope">
            <div>主叫：{{ scope.row.telAPrefix }}</div>
            <div>被叫：{{ scope.row.telBPrefix }}</div>
          </template>
        </el-table-column>
        <el-table-column label="主叫/被叫">
          <template #default="scope">
            <div>主叫：{{ scope.row.telA }}</div>
            <div>被叫：{{ scope.row.telB }}</div>
          </template>
        </el-table-column>
        <el-table-column label="客户端ip">
          <template #default="scope">
            <div>{{ scope.row.ip == null?'-':scope.row.ip }}</div>
          </template>
        </el-table-column>
        <el-table-column label="命中类型">
          <template #default="scope">
            <div class="hitType-color">{{ scope.row.hitType == null?'-':scope.row.hitType }}</div>
          </template>
        </el-table-column>

        <el-table-column label="风险等级">
          <template #default="scope">
            <div class="hitType-color"> {{ scope.row.riskText === null ? "-" : scope.row.riskText }}</div>
          </template>
        </el-table-column>
        <el-table-column label="敏感词" width="280">
          <!-- <template #default="scope">
        <div :class="scope.row.auditStatus == 2?'violations':'normal'">{{ scope.row.hitTypeDesc == null?'暂无结果':scope.row.hitTypeDesc }}</div>
     </template> -->
          <template #default="scope">
            <el-tag size="small" class="keywords" effect="dark" :type="scope.row.auditStatus == 2?'danger':''"
              v-for="(item, i) in scope.row.hitTypeDesc?.split(',')" :key="i">
              {{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="开场白">
          <template #default="scope">
            <div>{{ text40(scope.row) }}</div>
            <!-- <div>{{ scope.row.callerTranslatedContent == null||scope.row.callerTranslatedContent == ''?(scope.row.calleeTranslatedContent == null||scope.row.calleeTranslatedContent == ''?'-':scope.row.calleeTranslatedContent):scope.row.callerTranslatedContent }}</div> -->
          </template>
        </el-table-column>
        <el-table-column label="审核结果">
          <template #default="scope">
            <div>{{ typelist[scope.row.auditStatus] }}</div>
          </template>
        </el-table-column>
        <el-table-column label="质检状态">
          <template #default="scope">
            <div>{{ typelist2[scope.row.translatedResult] }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="质检日期"></el-table-column>
      </el-table>
    </div>
    <div class="flex-nowrap-flex-end">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[20, 50, 100, 200]" :page-size="searchForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="tableData.total">
      </el-pagination>
    </div>
    <el-dialog v-model="centerDialogVisible" title="对话详情" width="60%" :destroy-on-close="true">
      <div class="outShowBox" v-loading="elDialog">
        <div v-if="nextShow" class="flex-nowrap-no-between">
          <div class="left-out-box">
            <el-descriptions class="margin-top" title="" :column="1" size="small" border>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    服务商ip
                  </div>
                </template>
                <div class="flex-wrap-center-center-center  ">
                  {{ auditRow.ip || '--' }}
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    主叫前缀
                  </div>
                </template>
                <div class="flex-wrap-center-center-center  ">
                  {{ auditRow.telAPrefix }}
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    主叫
                  </div>
                </template>
                <div class="flex-wrap-center-center-center  ">
                  {{ auditRow.telA }}
                </div>
              </el-descriptions-item>

              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    被叫前缀
                  </div>
                </template>
                <div class="flex-wrap-center-center-center  ">
                  {{ auditRow.telBPrefix }}
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    被叫
                  </div>
                </template>
                <div class="flex-wrap-center-center-center  ">
                  {{ auditRow.telB }}
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    通话时间
                  </div>
                </template>
                <div class="flex-wrap-center-center-center  ">
                  {{strli}}

                  <!-- {{new Date(auditRow.timestamp).format('yyyy-MM-dd hh:mm:ss')}} -->
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    行业类型
                  </div>
                </template>
                <div class="flex-wrap-center-center-center  ">
                  {{ auditRow.industry }}
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    命中类型
                  </div>
                </template>
                <div class="type-height flex-wrap-center-center-center  ">
                  {{ auditRow.hitType == null?'-':auditRow.hitType }}
                </div>
              </el-descriptions-item>

              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    命中关键字
                  </div>
                </template>
                <div class="   ">
                  <el-tag size="small" class="keywords" effect="dark" :type="auditRow.auditStatus == 2?'danger':''"
                    v-for="(item, i) in auditRow.hitTypeDesc?.split(',')" :key="i">
                    {{item}}
                  </el-tag>
                  <!-- <el-tag size="small" class="keywords" effect="dark" :type="auditRow.auditStatus == 2?'danger':''" v-for="(item, i) in auditRow.hitTypeDesc?.split(',')" :key="i">
                                {{item}}
                            </el-tag>
                             <el-tag size="small" class="keywords" effect="dark" :type="auditRow.auditStatus == 2?'danger':''" v-for="(item, i) in auditRow.hitTypeDesc?.split(',')" :key="i">
                                {{item}}
                            </el-tag>
                             <el-tag size="small" class="keywords" effect="dark" :type="auditRow.auditStatus == 2?'danger':''" v-for="(item, i) in auditRow.hitTypeDesc?.split(',')" :key="i">
                                {{item}}
                            </el-tag>
                             <el-tag size="small" class="keywords" effect="dark" :type="auditRow.auditStatus == 2?'danger':''" v-for="(item, i) in auditRow.hitTypeDesc?.split(',')" :key="i">
                                {{item}}
                            </el-tag> -->
                </div>
              </el-descriptions-item>
            </el-descriptions>
            <div class="flex-wrap-center-center-center  vos-box">
              <audio class="recordSon" :src="recordUrl" controls="controls"></audio>
            </div>
          </div>
          <div class="right-out-box">
            <div class="dialogue">
              <div v-for="(item,i) in dialogueList" :key="i" :class="item?.channel_id == 1?'item-left':'item-right'">
                <div v-if="item?.channel_id == 1" class="item-left portrait1">
                  <el-avatar :size="40" :src="require(`@/assets/images/telA.png`)" />
                </div>
                <div>
                  <div :class="item?.channel_id == 1?'title-left':'title-right'" class="title-item">
                    {{ item?.channel_id == 1?'主叫':'被叫' }}</div>
                  <div class="fa-triangle">
                    <div v-if="item?.channel_id == 1" class="triangle1"></div>
                    <div class="item-text" :style="item?.channel_id == 1?'background: #fff;':'background: #95ec69;'"
                      v-html="$filter.mateKeyword(item?.text,hitTypeDesc)"></div>
                    <div v-if="item?.channel_id == 0" class="triangle2"></div>
                  </div>
                </div>
                <div v-if="item?.channel_id == 0" class="portrait2 item-right">
                  <el-avatar :size="40" :src="require(`@/assets/images/telB.png`)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="noTextBox">
          暂无质检记录！！！
        </div>
        <div style="margin-top: 15px;">
          <div class="flex-nowrap-space-between">
            <div class="flex-nowrap">
              <el-radio-group v-model="auditStatus">
                <el-radio :label="1" border>正常</el-radio>
                <el-radio :label="2" border>违规</el-radio>
                <el-radio :label="3" border>误判</el-radio>
                <el-radio :label="4" border>复审</el-radio>
              </el-radio-group>
            </div>
            <div class="flex-nowrap">
              <el-button size="mini" @click="nextRow(rowId,0)">上一条</el-button>
              <el-button size="mini" @click="nextRow(rowId,1)">下一条</el-button>
              <el-button type="primary" @click="auditCommit(auditStatus)" size="mini">确认</el-button>
              <el-button @click="centerDialogVisible = false" size="mini">取消</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { IconFont } from '@/utils/iconfont'
import { getDate } from '@/utils/timeToChoose.js'
import { ElMessage } from 'element-plus'
import record from '@/api/open/voiceCheck/record'
import voiceCheck from '@/api/open/voiceCheck/voiceCheck'
import voiceType from '@/api/open/voice/voiceType'
import VosRecord from "@/views/open/vosQuality/record/VosRecord.vue";
export default {
  components: {
    VosRecord,
    // IconFont,
  },
  data() {
    return {
      options: [
        {
          value: null,
          label: '全部',
        },
        {
          value: 3,
          label: '高',
        },
        {
          value: 2,
          label: '中',
        },
        {
          value: 1,
          label: '低',
        },
        {
          value: 0,
          label: '无',
        },
      ],

      strli: '',
      shortcuts: [
        {
          text: '近一周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '近一月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '近三个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
      searchForm: {
        page: 1,
        pageSize: 20,
        translatedResultList: [0],
        auditStatusList: [0, 1, 2, 3, 4],
        hitTypeStatus: '',
        read: '0',
        timeArr: [],
        risk: '',
      },
      readMap: new Map(), // 已读的历史记录集合
      cursor: 1,  // 指向最近一条查看过详情的质检记录
      typeName: 1,
      rowId: null,
      elDialog: false,
      nextShow: true,
      checkAll: false,
      isIndeterminate: true,
      checkAll2: false,
      isIndeterminate2: true,
      cities: [
        {
          label: 0,
          name: '质检成功',
        },
        {
          label: -1,
          name: '质检中',
        },
        {
          label: 1,
          name: '质检失败',
        },
      ],
      cities2: [
        {
          label: 0,
          name: '未审核',
        },
        {
          label: 1,
          name: '正常',
        },
        {
          label: 2,
          name: '违规',
        },
        {
          label: 3,
          name: '误判',
        },
        {
          label: 4,
          name: '复审',
        },
      ],
      typelist: {
        0: '未审核',
        1: '正常',
        2: '违规',
        3: '误判',
        4: '复审',
      },
      typelist2: {
        0: '质检成功',
        '-1': '质检中',
        1: '质检失败',
      },
      timeArr: [],
      options1: [
        {
          value: 'hitType',
          label: '等于',
        },
        {
          value: 'notHitType',
          label: '不等于',
        },
      ],
      options2: [],
      options3: [],
      tableData: {
        total: 0,
      },
      currentPage: 1,
      hitTypeDesc: [],
      recordUrl: '',
      hitType: '',
      auditRow: null,
      theQueryDialog: false,
      centerDialogVisible: false,
      voiceName: [],
      auditStatus: null,
      tableHeight: 70,
    }
  },
  created() {},
  watch: {},
  mounted() {
    // this.searchForm.timeArr = [
    //   getDate(0) + ' 00:00:00',
    //   getDate(0) + ' 23:59:59',
    // ]
    // if (this.$route.query.data != null && this.$route.query.data != undefined) {
    //   this.searchForm = JSON.parse(this.$route.query.data)
    // }
    // this.getTable()
    // this.vosUserIndustry()
  },
  methods: {
    changeRedStatus(val) {
      let findLine = this.$refs.multipleTable.data.find(
        (item) => item.id === val.id
      )
      if (findLine) {
        findLine.read = 1
      }
    },
    aFold(val) {
      console.log(val)
      if (val == 1) {
        this.tableHeight = 36
      } else {
        this.tableHeight = 70
      }
    },
    text40(val) {
      let text =
        val.callerTranslatedContent == null || val.callerTranslatedContent == ''
          ? val.calleeTranslatedContent == null ||
            val.calleeTranslatedContent == ''
            ? '-'
            : val.calleeTranslatedContent
          : val.callerTranslatedContent
      if (text.length > 40) {
        return text.substring(0, 40) + '...'
      } else {
        return text
      }
    },
    vosUserIndustry() {
      record.vosUserIndustry().then((res) => {
        this.options2 = res.data
      })
      voiceType.voiceName().then((res) => {
        this.options3 = res.data
      })
    },
    inquire() {
      this.getTable()
      this.$router.push({
        path: '/open/VosQuality',
        query: {
          activeName: 'first',
          data: JSON.stringify(this.searchForm),
        },
      })
    },
    blick() {
      this.searchForm = {
        page: 1,
        pageSize: 20,
        translatedResultList: [0],
        auditStatusList: [0],
        read: '0',
        timeArr: [],
      }
      this.$router.push({
        path: '/open/VosQuality',
      })
      this.getTable()
    },
    // 获取列表数据
    getTable() {
      this.searchForm.timeArr == null ? (this.searchForm.timeArr = []) : ''
      this.searchForm.createTimeBegin = this.searchForm.timeArr[0]
      this.searchForm.createTimeEnd = this.searchForm.timeArr[1]
      record.checkList(this.searchForm).then((res) => {
        this.tableData = res.data
        this.tableData.records.forEach((item, i) => {
          item.callText =
            item.callerTranslatedContent == null ||
            item.callerTranslatedContent == ''
              ? '-'
              : item.callerTranslatedContent.substring(0, 30) + '...'
          if (item.hitTypeDesc == null) {
            item.hitTypeDescArr = []
          } else {
            item.hitTypeDescArr = item.hitTypeDesc.split(',')
          }
        })
      })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.read === 1 || row.auditStatus === 1) {
        return 'green-row'
      } else if (row.auditStatus === 2) {
        return 'el-tab-row-error'
      } else if (row.auditStatus === 3) {
        return 'el-tab-row-warning'
      } else if (row.auditStatus === 4) {
        return 'el-tab-row-warning'
      }
      return ''
    },
    nextRow(id, next) {
      //0 上一条 1 下一条
      this.nextShow = false
      this.elDialog = true
      let nextJson = JSON.parse(JSON.stringify(this.searchForm))
      nextJson.checkId = id
      nextJson.queryType = next
      next === 0 ? --this.cursor : ++this.cursor
      if (this.readMap.has(this.cursor)) {
        this.elDialog = false
        this.openDiv(this.readMap.get(this.cursor))
        return
      }
      voiceCheck.vosCheckNext(nextJson).then((res) => {
        this.elDialog = false
        if (res.data.id != undefined || res.data.id != null) {
          this.openDiv(res.data)
        } else {
          ElMessage({
            showClose: true,
            message: next == 0 ? '已经是最后一条了！' : '已经是第一条了！',
          })
        }
      })
    },
    // 打开对话
    openDiv(row) {
      if (row.index) {
        this.cursor = row.index + 1
      }
      // 打开对话框，增加已读历史
      this.readMap.set(this.cursor, row)
      this.rowId = row.id

      voiceCheck
        .vosCheck({
          id: row.id,
        })
        .then((res) => {
         this.changeRedStatus(row)
        })
      if (row.hitTypeDesc != null) {
        this.hitTypeDesc = row.hitTypeDesc.split(',')
      }
      voiceCheck
        .getByCheckId({
          checkId: row.id,
        })
        .then((res) => {
          if (res.data == undefined) {
            ElMessage({
              showClose: true,
              message: '暂无质检记录。',
              type: 'warning',
            })
          } else if (res.data.translatedContent == null) {
            ElMessage({
              showClose: true,
              message: '暂无质检记录。',
              type: 'warning',
            })
          } else {
            this.recordUrl = row.fileUrl
            this.hitType = row.hitType
            this.auditStatus = row.auditStatus
            this.auditRow = row
            this.strli =
              row.timestamp.substring(0, 4) +
              '-' +
              row.timestamp.substring(4, 6) +
              '-' +
              row.timestamp.substring(6, 8) +
              ' ' +
              row.timestamp.substring(8, 10) +
              ':' +
              row.timestamp.substring(10, 12) +
              ':' +
              row.timestamp.substring(12, 14)
            this.centerDialogVisible = true
            this.dialogueList = JSON.parse(
              res.data.translatedContent
            ).payload.sentences
            // console.log( this.dialogueList ,'000000000000000000000000000');
            this.nextShow = true
            // if (row.auditStatus == 0) {
            //     this.auditCommit(1)
            // } else {
            //     return
            // }
          }
        })
      // this.quickConfirm(row)
    },
    // 提交审核状态
    auditCommit(auditStatus) {
      // if (this.auditStatus == 0) {
      //     auditStatus = 1
      // }
      voiceCheck
        .vosAudit({
          id: this.auditRow.id,
          auditStatus: auditStatus,
        })
        .then((res) => {
          if (res.code == 200) {
            this.auditRow.auditStatus = auditStatus
            this.centerDialogVisible = false
          }
        })
      this.auditRow.auditStatus = auditStatus
    },
    handleSizeChange(val) {
      this.searchForm.pageSize = val
      this.getTable()
    },
    handleCurrentChange(val) {
      this.searchForm.page = val
      this.currentPage = val
      this.getTable()
    },
    handleCheckAllChange(val) {
      if (val) {
        this.cities.map((item) => {
          this.searchForm.translatedResultList.push(item.label)
        })
      } else {
        this.searchForm.translatedResultList = []
      }

      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length
    },
    handleCheckAllChange2(val) {
      if (val) {
        this.cities2.map((item) => {
          this.searchForm.auditStatusList.push(item.label)
        })
      } else {
        this.searchForm.auditStatusList = []
      }

      this.isIndeterminate2 = false
    },
    handleCheckedCitiesChange2(value) {
      let checkedCount = value.length - 1
      this.checkAll2 = checkedCount === this.cities2.length
      this.isIndeterminate2 =
        checkedCount > 0 && checkedCount < this.cities2.length
    },
  },
}
</script>

<style lang="less" scoped>
.cell-item {
  min-width: 95px;
}
.conditions-box {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  font-size: 12px;
}

.conditions-in-title {
  padding: 0 15px;
}

.conditions-in-first {
  border-left: 1px solid #dcdfe6;
}

.screen-box {
  padding: 10px;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
}

.screen-text {
  width: 85%;
}

.screen-buttom {
  width: 15%;
}

.div-width-radio-group {
  width: 620px;
}

.hitType-color {
  color: #8b9efc;
}

.violations {
  padding: 5px 10px;
  background: #f56c6c;
  border-radius: 6px;
}

.normal {
  padding: 5px 10px;
  background: #8b9efc;
  border-radius: 6px;
}

.keywords {
  margin-right: 8px;
  margin-bottom: 4px;
}

.record {
  height: 5rem;
}

.dialogue {
  overflow-y: overlay;
  height: 32rem;
  padding: 0.425rem 1.5rem;
  background: #f5f5f5;
}

.outShowBox {
  // height: 36rem;
}

.recordSon {
  width: 95%;
  height: 2rem;
}

.item-text {
  display: inline-block;
  padding: 0.375rem;
  border-radius: 0.375rem;
  color: #000;
}

.item-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.item-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.title-left {
  text-align: left;
}

.noTextBox {
  height: 32rem;
  text-align: center;
  line-height: 60px;
}

.title-right {
  text-align: right;
}

.title-item {
  padding: 0.375rem;
}

.left-out-box {
  width: 35%;
}

.right-out-box {
  width: 63%;
}

.type-height {
  height: 6rem;
}

.vos-box {
  height: 2.7rem;
  border: 1px solid #dcdfe6;
}

.portrait1 {
  margin-top: 30px;
  margin-right: 25px;
}

.portrait2 {
  margin-top: 30px;
  margin-left: 25px;
}

.fa-triangle {
  position: relative;
}

.triangle1 {
  width: 0;
  height: 0;
  border-top: solid 10px #f5f5f5;
  border-left: solid 10px #f5f5f5;
  border-right: solid 10px #fff;
  border-bottom: solid 10px #f5f5f5;
  position: absolute;
  left: -20px;
  top: 7px;
}

.triangle2 {
  width: 0;
  height: 0;
  border-top: solid 10px #f5f5f5;
  border-left: solid 10px #95ec69;
  border-right: solid 10px #f5f5f5;
  border-bottom: solid 10px #f5f5f5;
  position: absolute;
  right: -20px;
  top: 7px;
}
</style>